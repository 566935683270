@mixin reset-button() {
    cursor: pointer;
    background: transparent;
    border: none;
    font-size: 1em;
    outline: none;
    padding: 0;
    border-radius: 0;
    text-decoration: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}


@mixin default-shadow {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}