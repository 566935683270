@use "./variables.scss";
@import "./theme.scss";

body {
    margin: 0;
    padding: 0;
}

body,
input {
    background-color: var(--background);
}

body,
button,
input,
ul,
ol {
    font-family: 'Roboto', sans-serif;
    color: var(--text);
}

.header {
    background-color: var(--primary);
    margin: 0;
    display: flex;
    justify-content: center;
    padding: 1em;

    &>* {
        background-color: var(--background);
    }
}

.content {
    margin: auto;
    max-width: variables.$max-width;
}

#updateready {
    text-align: center;
    padding: 1em;
}

.button-pane {
    padding: 0.2em;
    display: flex;
    justify-content: center;
}

.button {
    // display: inline-block;
    padding: 0.5em 1em;
    font-weight: 500;
    border: 1px solid;
    border-radius: 4px;
    background-color: var(--button-background);
    border-color: var(--button-border);
    transition: .2s cubic-bezier(.3, 0, .5, 1);
    transition-property: background-color, color;
    cursor: pointer;
    outline: none;

    &:hover {
        background-color: var(--button-background--hover);
        transition-duration: .1s;
    }

    &:focus-visible {
        transition: none;
        background-color: var(--button-background--focus);
    }



    &:active {
        background-color: var(--button-background--active);
        transition: none;
    }

    // &:disabled {
    //     color: #484f58;
    //     background-color: #21262d;
    //     border-color: #30363d;
    // }
}


h1,
h2,
h3 {
    font-weight: normal;
    margin-block-start: 0.2em;
    margin-block-end: 0.2em;
}

h1 {
    font-size: 1.4em;
}