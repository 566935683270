@use "sass:color";

$primary : #00e676;
$focus : color.adjust($primary, $lightness: 50%);

:root {
    --primary : #{$primary};
    --background: white;
    --text : black;
    --border : #e0e0e0;
    --hover : #f0f0f0;
    --focus: #{$focus};
    --text-disabled : #999999;

    --timeline--time:#f0f0f0;
    --timeline--walking:#999999;

    --button-background: #fff;
    --button-border: #999999;
    --button-background--hover: #f0f0f0;
    --button-background--active: #e0e0e0;
    --button-background--focus: #{$focus};    
}

@media (prefers-color-scheme: dark) {
    :root {
        --background: #383838;
        --text:#eeeeee;
        --border : #999999;
        --hover : #666666;
        --focus: #667667;
        --text-disabled : #999999;

        --timeline--time:#666666;

        --button-background: #383838;
        --button-border: #999999;
        --button-background--hover: #666666;
        --button-background--active: #555555;
        --button-background--focus: #667667;    
    }
}