@use "../../variables.scss";
route-search {
    border-radius: variables.$card-border-radius;
    width: 100%;
    max-width: variables.$max-width;
    overflow: visible;
    display: block;
}

.route-search {
    &__abfahrt {
        border-bottom: 0.1em solid var(--border);
    }
}