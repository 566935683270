@use "../../utils.scss";
@use "../../variables.scss";
stop-search {
    display: block;
    position: relative;
    width: 100%;
    &:hover {
        background:var(--hover);
    }
    background-color: inherit;
    &:first-of-type {
        --top-radius : #{variables.$card-border-radius};
        border-top-left-radius: variables.$card-border-radius;
        border-top-right-radius: variables.$card-border-radius;
    }
    &:not(:first-of-type) {
        --top-radius : 0;
    }
    &:last-of-type {
        border-bottom-left-radius: variables.$card-border-radius;
        border-bottom-right-radius: variables.$card-border-radius;
    }
}

.stop-search {    
    @include utils.reset-button();
    display: block;
    text-align: start;
    padding: 0.2em 0.5em 0.5em 0.5em;
    width: 100%;
    border-radius: inherit;

    &:focus-visible {
        background-color: var(--focus); 
    }
    &__label {
        font-size: .75em;
    }

    &__search {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        background-color: var(--background); 
        width: 100%;
        border-top-left-radius: var(--top-radius);
        border-top-right-radius: var(--top-radius);
        border-bottom-left-radius: variables.$card-border-radius;
        border-bottom-right-radius: variables.$card-border-radius;
        position: absolute;
        min-height: 20em;
        top:0;
        @include utils.default-shadow();
        z-index: 1;
    }

    &__search-input-container {
        padding: 0.2em 0.5em 0 0.5em;
        border-bottom: 0.1em solid var(--border);
    }

    &__search-input {
        width: 100%;
        padding: 0.5em 0;
        border: none;
        font-size: 1em;
        outline: none;
    }

    &__results {
        overflow-y: auto;
        overflow-x: hidden;
    }
}